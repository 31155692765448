<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">非凸科技受邀出席第十届HED峰会</p>
              <div class="fst-italic mb-2">2020年12月08日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >第十届HED峰会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <figure class="mb-4">
                  <img
                    class="img-fluid rounded w-100"
                    src="../../assets/img/news/03/image01.jpg"
                    alt="非凸科技受邀出席第十届HED峰会"
                  />
                </figure>
                <section class="mb-5">
                  <p class="mb-4">
                    2020年12月8日，由财视中国联合中泰证券举办的“第十届HED峰会”在深圳隆重举行。机构投资人、银行、保险、资管、母基金、公募基金、私募基金、券商、期货等汇聚一堂，展望资产管理及财富管理行业的机遇与挑战。
                  </p>
                  <p class="mb-4">
                    非凸科技受邀出席FOF分论坛，就“科技赋能FOF新发展”与同行业负责人展开深度交流。
                  </p>
                  <p class="mb-4">
                    非凸科技参会代表在会上表示，非凸科技以“AI赋能交易”为宗旨，在机器学习或深度学习上具备一定的优势。首先是人才的招募与培养，目前非凸科技深度学习部门硕博比例达100%，且均有机器学习/深度学习背景，研究创新能力我们有信心位于市场前列；其次，秉承产学结合的宗旨，定期追踪深度学习领域前沿发展，并将最新模型及算法应用到我们的信号预测上来，在模型创新及稳定方面保持领先地位。
                  </p>
                  <img
                    class="img-fluid rounded w-100 mb-4"
                    src="../../assets/img/news/03/image02.jpg"
                    alt="非凸科技受邀出席第十届HED峰会"
                  />
                  <p class="text-center mb-4">非凸科技 参会代表</p>
                  <p class="mb-4">
                    实际上，深度学习模型训练计算起来非常耗时，非凸科技又是如何解决这个问题的呢？非凸科技参会代表作出回答，在硬件方面，训练模型时，非凸科技有GPU服务器集群全天候工作；同时，我们会自己写一些早停函数及评价指标来使得模型较早达到符合我们要求的预期。除此之外，当训练好模型部署到实盘当中时，传统的方法直接调用训练时的预测函数，从高级语言到适用交易的低级语言之间转换效率较低。因此，非凸科技摒弃高级语言的默认计算方法，将其进行解析后用C++的底层架构进一步编译，这样在计算速度上我们保证了领先地位。
                  </p>
                  <p class="mb-4">
                    非凸科技参会代表还表示，每家算法服务商都各具特色，之于非凸科技，主要有这几点特色。一是很强的投研实力保证了算法效果的持续性；二是满足不同客户不同需求的个性化定制；三是问题解决的及时性，响应速度快；四是算法交易系统的本地化部署，满足合规要求，保护客户隐私。
                  </p>
                  <p class="mb-4">
                    最后，非凸科技参会代表还就算法交易未来市场规模进行了预测。他表示，目前A股市场全年交易额在200万亿元，算法交易的占比约20%，如果每年算法交易的渗透率提升，再加之市场规模的增长，我们预估未来5年内算法交易的市场规模有望突破100亿元，10年内突破200亿元。
                  </p>
                  <p class="mb-4">
                    非凸科技希望能秉承“亲近客户”的发展理念，以技术提升服务，以服务促进发展，向为客户提供“金融服务最优解决方案”的行业高地奋进。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News03",
};
</script>

<style></style>
